<template>
    <div class="search-form">
        <b-form>
            <b-form-row>
                <div cols="12" class="col-12 search-title">
                    <label class="label">{{ $t('searchForm.supplier.label') }}</label>
                </div>
            </b-form-row>
            <b-form-row class="align-items-center mb-4">
                <div class="col-12">
                    <multiselect
                        v-model="searchValues.supplier"
                        :options="suppliersOptions"
                        :multiple="false"
                        :searchable="true"
                        :show-labels="false"
                        :placeholder="$t('searchForm.supplier.placeholder')"
                        label="id"
                        track-by="id"
                        :internal-search="false"
                        :clear-on-select="false"
                        :preserve-search="true"
                        :close-on-select="true"
                        :options-limit="300"
                        :max-height="600"
                        :show-no-results="true"
                        :hide-selected="false"
                        @search-change="searchSuppliers"
                        :option-height="72"
                    >
                        <template #option="{ option }">
                            <div class="form-row">
                                <div class="col-10 multiselect-text-left">
                                    {{ option.name }}
                                    <br />
                                    <small class="fw-light">{{ option.ico }}</small>
                                </div>
                                <div class="col-2 multiselect-text-end">
                                    <small class="text-nowrap fw-light">{{ $tc("searchForm.supplier.tenders", option.tender_count) }}</small>
                                    <br />
                                    <small class="text-nowrap fw-light">{{ $tc("searchForm.supplier.contracts", option.contract_count) }}</small>
                                </div>
                            </div>
                        </template>
                        <template #singleLabel="{ option }">
                            <span class="form-row">
                                <span class="col-10 multiselect-text-left text-truncate">{{ option.name }}</span>
                                <span class="col-2 multiselect-text-end">{{ option.ico }}</span>
                            </span>
                        </template>
                        <template #noOptions>
                            <span >{{ $t("searchForm.supplier.tooltip.lessThanThree") }}</span>
                        </template>

                        <template #noResult="{data}">
                            <span>
                                <template v-if="data && data.search.length < minimumCharacters">
                                    {{ $t("searchForm.supplier.tooltip.lessThanThree") }}
                                </template>
                                <template v-else-if="suppliersMultiselectIsBusy">
                                    {{ $t("searchForm.supplier.tooltip.busy") }}
                                </template>
                                <template v-else>
                                    {{ $t("searchForm.supplier.tooltip.empty[0]") }}
                                    <ol class="fw-light mt-1 mb-0 small">
                                        <li>{{ $t("searchForm.supplier.tooltip.empty[1]") }}</li>
                                        <li>{{ $t("searchForm.supplier.tooltip.empty[2]") }}</li>
                                    </ol>
                                </template>
                            </span>
                        </template>
                    </multiselect>
                </div>
            </b-form-row>

            <b-form-group class="text-center">
                <template v-if="research">
                    <input type="hidden" name="research_name" id="id_research_name" v-model="research.name" />
                    <input type="hidden" name="research_id" id="id_research_id" v-model="research.id" />
                </template>
                <br />
                <span id="search-submit-button"
                    class="d-inline-block"
                    v-b-tooltip.hover.top :title="$tc(buttonTooltip, minimumCharacters )">
                    <b-button variant="primary" size="lg" :disabled="searchValues.supplier == null" @click="submit">
                        {{ $t("searchForm.submit.label") }}
                        <font-awesome-icon icon="search" />
                    </b-button>
                </span>
            </b-form-group>
        </b-form>
    </div>
</template>

<script>
import { CONFIG } from "../config.js";

import axios from "axios";
import applicationLogMixin from "@/plugins/applicationLogMixin.js";
import searchFormMixin from "@/plugins/searchFormMixin.js";

export default {
    props: {},
    mixins: [applicationLogMixin, searchFormMixin],
    data: function() {
        return {
            searchValues: {
                supplier: null
            },
            cancelToken: null,
            suppliersOptions: [],
            suppliersMultiselectIsBusy: false,
            suppliersCancelToken: null,
            suppliersTimer: null,
            query: null,
            minimumCharacters: 3
        };
    },
    computed: {
        research: function() {
            return this.$store.getters.research;
        },
        buttonTooltip: function() {
            if (this.searchValues.supplier != null) {
                return 'searchForm.submit.tooltip.click'
            }
            else if(this.query === null || this.query.length == 0){
                return 'searchForm.submit.tooltip.create'
            }
            else if (this.query.length < this.minimumCharacters) {
                return 'searchForm.submit.tooltip.moreCharacters'
            }
            else if (this.query.length >= this.minimumCharacters && this.suppliersOptions.length > 0) {
                return 'searchForm.submit.tooltip.select'
            }
            else if (this.query.length >= this.minimumCharacters && this.suppliersOptions.length == 0) {
                return 'searchForm.submit.tooltip.noResults'
            }
            return 'searchForm.submit.tooltip.create'
        }
    },
    methods: {
        submit: function() {
            this.$store.dispatch("updateResearch", {
                search_criteria: this.searchValues
            });

            this.loadCriteriaLog()
                .then(log => this.$log('RESEARCH_CREATION', {
                        criteria: log,
                        type: this.research.research_type
                    })
                ).then(() => this.$emit("search-form-submit"))
        },
        loadCriteriaLog: function() {
            return new Promise((resolve) => {
                resolve(this.buildCriteriaLog(this.searchValues))
            })
        },
        searchSuppliers: function(query) {
            this.suppliersOptions = [];
            // start busy text
            this.suppliersMultiselectIsBusy = true;

            if (this.suppliersTimer) {
                clearTimeout(this.suppliersTimer);
            }

            this.query = query;
            if (query.length >= this.minimumCharacters) {
                // cancel already running request
                // check whether there is a call to server running
                // if so, cancel the request, new one will be planned instead
                if (this.suppliersCancelToken != null) {
                    this.suppliersCancelToken.cancel("Operation canceled by the user.");
                    this.suppliersCancelToken = null;
                    // if busy text is closed renew it
                    this.suppliersMultiselectIsBusy = true;
                }

                this.suppliersTimer = setTimeout(() => {
                    // initiate cancel token used to identify, whether ther is a request running already
                    this.suppliersCancelToken = axios.CancelToken.source();
                    // if busy text is closed renew it
                    this.suppliersMultiselectIsBusy = true;
                    this.$store.getters.api
                        .get(CONFIG.api.endpoints.subjects(query), { cancelToken: this.suppliersCancelToken.token })
                        .then(response => this.suppliersOptions = response.data)
                        .catch(e => console.log(e))
                        .finally(() => {
                            this.suppliersMultiselectIsBusy = false;
                            this.suppliersCancelToken = null;
                        })
                }, 500)
            } else {
                this.suppliersOptions = [];
                this.suppliersMultiselectIsBusy = false
            }
        },
    }
};
</script>

<style scoped lang="scss">
#id_fulltextKeywords {
    max-height: 50px;
}

.bar_container {
    position: relative;
    display: inline-block;
}

.form-row .price_slider {
    padding-left: 11px;
    padding-right: 12px;
}

.price_histogram {
    position: relative;
    top: 5px;
}

.count_badge {
    height: 24px;
}
</style>