<template>
    <router-link v-if="to" :to="to" @click="$emit('click', $event)">
        <slot></slot>
    </router-link>
    <a v-else-if="href" :href="href" @click="$emit('click', $event)" :target="target">
        <slot></slot>
    </a>
</template>

<script>
    export default {
        name: "b-link",
        props: {
            to: {type: [String, Object]},
            href: {type: String},
            target: {type: String}
        }
    }
</script>

<style lang="scss">

</style>