<template>
    <code
        v-for="([k, v], i) in Object.entries(data)"
        :key="`metadata-${i}`"
        class="d-block"
        style="overflow-wrap: anywhere"
    >
        <span class="text-muted d-inline-block align-top pe-1">{{ k }}:</span>
        <template v-if="v === ''">''</template>
        <template v-else-if="v === null || (Array.isArray(v) && !v.length)">
            <i class="text-info">&lt;null&gt;</i>
        </template>
        <template v-else-if="Array.isArray(v)">
            <br>
            <ul class="list-unstyled d-inline-block mb-0 ms-5">
                <li v-for="(n, j) in v" :key="[k, i, j].join('-')" :class="{'object': n.constructor === Object}">
                    <ActionMetadata v-if="n.constructor === Object" :data="n" />
                    <template v-else>{{ n }}</template>
                </li>
            </ul>
        </template>
        <a v-else-if="typeof v === 'string' && v.startsWith('http')" :href="v" target="_blank">{{ v }}</a>
        <template v-else-if="v.constructor === Object">
            <br>
            <ul class="list-unstyled d-inline-block mb-0 ms-5">
                <li><ActionMetadata :data="v" /></li>
            </ul>
        </template>
        <template v-else>{{ v }}</template>
    </code>
</template>

<script>
export default {
    props: {
        data: { type: Object, required: true }
    }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/custom';

code {
    font-size: 14px;
}
li.object {
    padding: 2px 8px;
    border-radius: 2px;
    border: 1px solid #dedede;
}

li.object + li.object {
    margin-top: 2px;
}
</style>