<template>
    <b-container fluid class="h-100" v-cloak>
        <div class="row justify-content-center">
            <div class="col-md-12 col-lg-11 col-xl-10">
                <b-alert :show="dismissCountDown" dismissible fade :variant="alertVariant" @dismiss-count-down="countDownChanged" class="mt-2">
                    {{ alertMessage }}
                </b-alert>

                <div class="position-relative">
                    <ResearchHeader :type="researchType" />
                    <div class="copy-to-clipboard-wrapper">
                        <span id="copy-to-clipboard"
                            class="d-inline-block"
                            v-b-tooltip.hover.left :title="$t('result.permalink.tooltip')"
                            @click="copyToClipboard(permalink)">
                            <font-awesome-icon icon="link"  />
                        </span>
                    </div>
                </div>

                <div class="row controls-row align-items-end">
                   <div class="col">
                        <div class="row justify-content-center text-center button-row">
                            <div class="col-4">
                                <b-button size="sm" variant="outline-primary" class="me-1"
                                    @click="$ebus.emit('show-overlay', {
                                        component: 'search-form',
                                        showBackButton: false,
                                        listeners: {'searchFormSubmit': onSearchFormSubmit}
                                    })"
                                >
                                    {{ $t("result.button.search.label") }}
                                </b-button>
                                <template v-if="!isSaveEnabled">
                                    <span id="save-btn"
                                        class="d-inline-block"
                                        v-b-tooltip.hover.bottom :title="$t('result.button.save.tooltip')">
                                        <b-button size="sm" variant="primary" disabled class="tooltiphover me-1">{{ $t("result.button.save.label") }}</b-button>
                                    </span>
                                </template>
                                <b-button v-else size="sm" variant="primary" class="me-1"
                                    @click="$ebus.emit('show-overlay', {
                                        component: 'research-save-form',
                                        showBackButton: false,
                                        props: {selected: selected, orderby: orderby}})"
                                >
                                    {{ $t("result.button.save.label") }}
                                </b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-md-12 col-lg-11 col-xl-10 h-100">
                <slot v-if="arePrerequisitiesLoaded"></slot>
                <Loader v-else />
            </div>
        </div>
    </b-container>
</template>


<script>
import ResearchHeader from '@/components/ResearchHeader.vue';
import applicationLogMixin from "@/plugins/applicationLogMixin.js";
import Loader from "@/components/Loader.vue";

export default {
    props: {
        researchType: {
            validator: function (value) {
                return ["MarketResearchType.TENDERS", "MarketResearchType.SUPPLIERS", "MarketResearchType.SUPPLIER_TENDERS"]
                    .indexOf(value) !== -1
            },
            required: true
        },
        isSaveEnabled: { type: Boolean, default: true },
        selected: {type: Array, default: () => []},
        orderby: {type: String}
    },
    data: function() {
        return {
            dismissCountDown: 0,
            dismissSecs: 5,
            alertVariant: null,
            alertMessage: null,
            arePrerequisitiesLoaded: false
        };
    },
    mixins: [applicationLogMixin],
    components: {
        ResearchHeader,
        Loader
    },
    computed: {
        isTendersResearch: function() {
            return this.researchType == "MarketResearchType.TENDERS"
        },
        isSuppliersResearch: function() {
            return this.researchType == "MarketResearchType.SUPPLIERS"
        },
        isSupplierTendersResearch: function() {
            return this.researchType == "MarketResearchType.SUPPLIER_TENDERS"
        },
        research: function() {
            return this.$store.getters.research
        },
        permalinkBaseUrl: function() {
            return window.location.origin + window.location.pathname;
        },
        permalink: function() {
            if (this.research && this.research.search_criteria) {
                return this.permalinkBaseUrl
                    + "?research_name=" + (this.research.name ? encodeURIComponent(this.research.name) : "")
                    + "&criteria=" + this.encodeCriteria(this.research.search_criteria)
            }

            return null
        }
    },
    created: function() {
        this.arePrerequisitiesLoaded = false

        // set current research if needed (direct access of results page)
        if (!this.research) {
            var name = this.$route.query.research_name
            this.$store.commit("setResearch", { research_type: this.researchType, name: name ? name : null })
        }

        // set current research search criteria from query param
        var criteria_encoded = this.$route.query.criteria
        if (criteria_encoded) {
            this.$store.dispatch("updateResearch", { search_criteria: this.decodeCriteria(criteria_encoded) })
        }

        this.arePrerequisitiesLoaded = true
        this.$emit("research-prerequisities-loaded")
    },
    methods: {
        onSearchFormSubmit: function() {
            this.$emit("search-criteria-changed")
        },
        encodeCriteria: function(criteria) {
            return btoa(unescape(encodeURIComponent(JSON.stringify(criteria))))
        },
        decodeCriteria: function(criteria) {
            return JSON.parse(decodeURIComponent(escape(atob(criteria))))
        },
        countDownChanged: function(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        copyToClipboard: function (input) {
            this.$copyText(input).then(
                () => {
                    this.alertVariant = "success"
                    this.alertMessage = this.$t("result.permalink.success")
                    this.dismissCountDown = this.dismissSecs
                },
                () => {
                    this.alertVariant = "danger"
                    this.alertMessage = this.$t("result.permalink.error")
                    this.dismissCountDown = this.dismissSecs
                })
        }
    }
};
</script>

<style scoped lang="scss">
@import 'src/scss/custom';

#copy-to-clipboard {
    cursor: pointer;

    &:hover {
        color: $primary
    }
}

.copy-to-clipboard-wrapper {
    position: absolute;
    top: 40px;
    right: 0px;
}
</style>