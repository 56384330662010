export default {
    props: {
        item: { type: Object, required: true },
        selectedIds: { type: Array, required: true }
    },
    data: function() {
        return {
            selected: false
        };
    },
    computed: {
        isSelected: function() {
            return this.selectedIds && this.selectedIds.includes(this.item.id)
        }
    },
    mounted: function() {
        this.selected = this.isSelected
    },
    watch: {
        selectedIds: {
            handler: function() {
                this.selected = this.isSelected
            },
            deep: true
        },
        item: function() {
            this.selected = this.isSelected
        }
    },
    methods: {
        rowSelected: function() {
            this.$emit("rowSelected", this.item.id, !this.isSelected);
        }
    }
};
