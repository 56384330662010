import i18n from "@/plugins/i18n.js"

import moment from 'moment';
moment.locale('cs-CZ');
moment.updateLocale('cs-CZ', {
    months : {
        standalone: 'ledna_února_března_dubna_května_června_července_srpna_září_října_listopadu_prosince'.split('_'),
        format: 'leden_únor_březen_duben_květen_červen_červenec_srpen_září_říjen_listopad_prosinec'.split('_')
    }
});

function division(dividend, divisor) {
    if (!dividend) return ''
    return Number.parseFloat(dividend / divisor).toFixed(1)
}

function millions(value) {
    if (!value) return ''
    return division(value, 1.0e+6)
}

function number(value, tsep = " ", dsep = ",", digits=null) {
    if (!value) {
        return "0"
    }

    if (digits != null) {
        value = new Number(value.toFixed(digits))
    }

    var num = value.toLocaleString("en-US")
    return num
        .replace(/\./, "%dsep%")
        .replace(/,/g, "%tsep%")
        .replace(/%dsep%/, dsep)
        .replace(/%tsep%/g, tsep)
}

function integer(value, def='') {
    if (!value) return def
    return Number.parseInt(value)
}

const filters = {
    capitalize(value) {
        if (!value) return ''
        value = value.toString()
        return value.charAt(0).toUpperCase() + value.slice(1)
    },
    upper(value) {
        if (!value) return ''
        return value.toUpperCase()
    },
    lower(value) {
        if (!value) return ''
        return value.toLowerCase()
    },
    number(value, tsep = " ", dsep = ",", digits=null) {
        return number(value, tsep, dsep, digits)
    },
    integer(value, def='') {
        return integer(value, def)
    },
    thousands(value) {
        if (!value) return ''
        return division(value, 1.0e+3)
    },
    date(value) {
        if (!value) return ''
        return moment(value).format("D. MMMM YYYY")
    },
    datetime(value) {
        if (!value) return ''
        return moment(value).format("D. MMMM YYYY H:mm")
    },
    humanizePrice(value) {
        var price = "0"
        if (value >= 1e+9) {
            price = number(division(value, 1e+9)) + " " + i18n.global.t("currency.billions")
        } else if (value >= 1e+6) {
            price = number(division(value, 1e+6)) + " " + i18n.global.t("currency.millions")
        } else if (value > 0) {
            price = number(integer(value))
        }

        return price + " " + i18n.global.t("currency.czk")
    },
    date_short(value) {
        if (!value) return ''
        return moment(value).format("D. M. YYYY")
    }
}

export const FILTERS = filters;