<template>
    <div class="form-group">
        <label v-if="label" :class="labelClasses" :for="labelFor">
            {{ label }}
        </label>
        <div>
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    name: "b-form-group",
    props: {
        labelClass: {type: String},
        label: {type: String},

        labelCols: {type: Number},
        labelColsSm: {type: Number},
        labelColsMd: {type: Number},
        labelColsLg: {type: Number},
        labelColsXl: {type: Number},

        labelAlign: {type: String},
        labelAlignSm: {type: String},
        labelAlignMd: {type: String},
        labelAlignLg: {type: String},
        labelAlignXl: {type: String},

        labelSize: {type: String},
        labelFor: {type: String}
    },
    computed: {
        labelClasses: function() {
            return [
                'form-label',
                'd-block',
                this.labelClass,
                {
                    [`text-${this.labelAlign}`]: this.labelAlign,
                    [`text-sm-${this.labelAlignSm}`]: this.labelAlignSm,
                    [`text-md-${this.labelAlignMd}`]: this.labelAlignMd,
                    [`text-lg-${this.labelAlignLg}`]: this.labelAlignLg,
                    [`text-xl-${this.labelAlignXl}`]: this.labelAlignXl,

                    [`col-${this.labelCols}`]: this.labelCols,
                    [`col-sm-${this.labelColsSm}`]: this.labelColsSm,
                    [`col-md-${this.labelColsMd}`]: this.labelColsMd,
                    [`col-lg-${this.labelColsLg}`]: this.labelColsLg,
                    [`col-xl-${this.labelColsXl}`]: this.labelColsXl,

                    [`col-form-label`]: this.labelSize,
                    [`col-form-label-${this.labelSize}`]: this.labelSize,
                }
            ]
        },
        contentClasses: function() {
            return {
                'col': this.labelCols || this.labelColsSm || this.labelColsMd || labelColsLg || labelColsXl
            }
        }

    }
}
</script>

<style lang="scss">

</style>