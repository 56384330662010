import Vue from "vue";
import Vuex from "vuex";
import { CONFIG } from "./config.js";
import axios from "axios";
import router from "./router";

const djangoApi = axios.create({
    baseURL: CONFIG.api.baseUrl + "/api",
    withCredentials: true,
    withXSRFToken: true,
    xsrfCookieName: "csrftoken_new",
    xsrfHeaderName: "X-CSRFToken"
});

djangoApi.interceptors.response.use(
    function (response) {
        return response
    },
    function (error) {
        if (error.response && [401, 403].some(code => code == error.response.status)) {
            router.push({name: "login"})
        }

        if (error.response && error.response.status >= 500) {
            router.replace({ name: 'error' })
        }

        return Promise.reject(error)
    }
)

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        api: djangoApi,
        user: null,
        researches: [],
        currentResearch: null,
        concurrencyFromYear: new Date().getFullYear() - 5 // take only last 5 years
    },
    getters: {
        api: function(state) {
            return state.api;
        },
        apiBaseUrl: function(state) {
            return state.api.defaults.baseURL;
        },
        user: function(state) {
            return state.user;
        },
        isLoggedIn: state => {
            return !!state.user;
        },
        isExternal: state => {
            return state.user && state.user.external;
        },
        researches: state => {
            return state.researches;
        },
        researchById: state => id => {
            if (!state.researches) {
                return null;
            }

            var result = state.researches.filter(r => r.id == id);

            return result.length ? result[0] : null;
        },
        research: state => {
            return state.currentResearch;
        },
        concurrencyFromYear: state => {
            return state.concurrencyFromYear;
        }
    },
    actions: {
        loadUserProfile({ commit }) {
            return new Promise((resolve, reject) => {
                this.getters.api
                    .get(CONFIG.api.endpoints.userProfile)
                    .then(r => {
                        commit("setUser", r.data);
                        resolve();
                    })
                    .catch(function(error) {
                        commit("setUser", null);
                        reject(error);
                    });
            });
        },
        loadResearches({ commit }) {
            return new Promise((resolve, reject) => {
                this.getters.api
                    .get(CONFIG.api.endpoints.research.list)
                    .then(r => {
                        commit("setResearches", r.data);
                        resolve();
                    })
                    .catch(function(error) {
                        commit("setResearches", []);
                        reject(error);
                    });
            });
        },
        loadResearchById({ commit }, id) {
            return new Promise((resolve, reject) => {
                this.getters.api
                    .get(CONFIG.api.endpoints.research.get(id))
                    .then(r => {
                        commit("setResearch", r.data);
                        resolve();
                    })
                    .catch(function(error) {
                        commit("setResearch", []);
                        reject(error);
                    });
            });
        },
        setUserCompanyName({ commit }, name) {
            return new Promise((resolve, reject) => {
                let payload = name ? { name: name } : null
                this.getters.api
                    .post(CONFIG.api.endpoints.userCompany, payload)
                    .then(response => {
                        commit("setUser", response.data);
                        resolve(response.data);
                    })
                    .catch(e => {
                        reject(e);
                    });
            })
        },
        updateResearch({commit, getters}, patch) {
            commit(
                "setResearch",
                {...getters.research, ...patch}
            )
        }
    },
    mutations: {
        removeResearchById(state, id) {
            state.researches = state.researches.filter(r => r.id != id);
        },
        setUser(state, user) {
            state.user = user;
        },
        setResearches(state, researches) {
            state.researches = researches;
        },
        setResearch(state, research) {
            state.currentResearch = research;
        },
        clearResearch(state) {
            state.currentResearch = null
        }
    }
});

export default store;
