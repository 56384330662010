import { createApp, configureCompat } from 'vue';
import App from "./App.vue";
import moment from 'moment';
import Multiselect from "vue-multiselect";
import VueClipboard from "vue-clipboard2";
import UUID from 'vue-uuid';
import VueCookies from 'vue-cookies';
import VueGtm from '@gtm-support/vue-gtm';
import BTable from '@/components/bootstrap/BTable.vue';
import BPagination from '@/components/bootstrap/BPagination.vue';
import BNavbarBrand from '@/components/bootstrap/BNavbarBrand.vue';
import BNavbar from '@/components/bootstrap/BNavbar.vue';
import BNavItem from '@/components/bootstrap/BNavItem.vue';
import BNavbarToggle from '@/components/bootstrap/BNavbarToggle.vue';
import BCollapse from '@/components/bootstrap/BCollapse.vue';
import BNavbarNav from '@/components/bootstrap/BNavbarNav.vue';
import BNavItemDropdown from '@/components/bootstrap/BNavItemDropdown.vue';
import BDropdownItem from '@/components/bootstrap/BDropdownItem.vue';
import BDropdown from '@/components/bootstrap/BDropdown.vue';
import BContainer from '@/components/bootstrap/BContainer.vue';
import BBadge from '@/components/bootstrap/BBadge.vue';
import BLink from '@/components/bootstrap/BLink.vue';
import BFormInput from '@/components/bootstrap/BFormInput.vue';
import BButton from '@/components/bootstrap/BButton.vue';
import BForm from '@/components/bootstrap/BForm.vue';
import BFormGroup from '@/components/bootstrap/BFormGroup.vue';
import BFormRow from '@/components/bootstrap/BFormRow.vue';
import BFormRadioGroup from '@/components/bootstrap/BFormRadioGroup.vue';
import BAlert from '@/components/bootstrap/BAlert.vue';
import BTab from '@/components/bootstrap/BTab.vue';
import BTabs from '@/components/bootstrap/BTabs.vue';
import BIconQuestionCircle from "@/components/bootstrap/BIconQuestionCircle.vue"
import BInputGroup from "@/components/bootstrap/BInputGroup.vue"
import BFormSelect from "@/components/bootstrap/BFormSelect.vue"
import { Tooltip } from 'bootstrap';
import VueSlider from "vue-3-slider-component";

import "@/plugins/fontawesome";
import router from "./router";
import store from "./store";
import "./filters";
import { CONFIG } from "./config.js";

import i18n from "./plugins/i18n.js";

import { FILTERS } from './filters.js';

import ebus from "./ebus.js";

configureCompat({
    INSTANCE_EVENT_EMITTER: 'suppress-warning',
    GLOBAL_PROTOTYPE: 'suppress-warning',
    GLOBAL_EXTEND: 'suppress-warning',
    WATCH_ARRAY: false
});

const app = createApp(App);

if (CONFIG.gtmkey) {
    app.use(VueGtm, {
        id: CONFIG.gtmkey,
        vueRouter: router,// Pass the router instance to automatically sync with router (optional)
        enabled: !!CONFIG.gtmkey
    });
}

// momentjs
moment.locale('cs-CZ');

var relativeTimeFomatter = (number, withoutSuffix, key, isFuture) => {
    return i18n.global.tc(`relativeTime.${key}${isFuture ? "" : "_past"}`, number);
}

moment.updateLocale('cs-CZ', {
    months: {
        standalone: 'ledna_února_března_dubna_května_června_července_srpna_září_října_listopadu_prosince'.split('_'),
        format: 'leden_únor_březen_duben_květen_červen_červenec_srpen_září_říjen_listopad_prosinec'.split('_'),
    },
    monthsShort : 'led_úno_bře_dub_kvě_čvn_čvc_srp_zář_říj_lis_pro'.split('_'),
    relativeTime: {
        future: function (number) {
            if (number == i18n.global.t("relativeTime.d")) {
                number = i18n.global.tc("relativeTime.dd", 1)
            }

            return i18n.global.tc(`relativeTime.future`, parseInt(number), {s: number});
        },
        past: function (number) {
            if (number == i18n.global.t("relativeTime.d_past")) {
                return number
            }

            return i18n.global.t("relativeTime.past", {s: number})
        },
        s: relativeTimeFomatter,
        ss: relativeTimeFomatter,
        m: relativeTimeFomatter,
        mm: relativeTimeFomatter,
        h: relativeTimeFomatter,
        hh: relativeTimeFomatter,
        d: relativeTimeFomatter,
        dd: relativeTimeFomatter,
        M: relativeTimeFomatter,
        MM: relativeTimeFomatter,
        y: relativeTimeFomatter,
        yy: relativeTimeFomatter
    },
    longDateFormat : {
        L : 'DD.MM.YYYY',
        l : 'D. M. YYYY',
        LLL : 'D MMMM YYYY HH:mm',
        lll : 'D. M. YYYY HH:mm'
    }
})

app.config.globalProperties.$moment = moment
// momentjs end

app.use(UUID);
app.use(VueCookies);

VueClipboard.config.autoSetContainer = true;
app.use(VueClipboard);

app.component("multiselect", Multiselect);
app.component('b-table', BTable)
app.component('b-pagination', BPagination)
app.component('b-navbar-brand', BNavbarBrand)
app.component('b-navbar', BNavbar)
app.component('b-nav-item', BNavItem)
app.component('b-navbar-toggle', BNavbarToggle)
app.component("b-collapse", BCollapse);
app.component("b-navbar-nav", BNavbarNav);
app.component("b-nav-item-dropdown", BNavItemDropdown);
app.component("b-dropdown-item", BDropdownItem);
app.component("b-dropdown", BDropdown);
app.component("b-container", BContainer);
app.component("b-badge", BBadge);
app.component("b-link", BLink);
app.component("b-button", BButton);
app.component("b-form", BForm);
app.component("b-form-row", BFormRow);
app.component("b-form-group", BFormGroup);
app.component("b-input-group", BInputGroup);
app.component("b-form-select", {
    extends: BFormSelect,
    compatConfig: {
        MODE: 3
    }
});
app.component("b-alert", BAlert);
app.component("b-tab", BTab);
app.component("b-icon-question-circle", BIconQuestionCircle);
app.component("b-tabs", {
    extends: BTabs,
    compatConfig: {
        MODE: 3
    }
});
app.component("b-form-radio-group", {
    extends: BFormRadioGroup,
    compatConfig: {
        MODE: 3
    }
});
app.component("b-form-input", {
    extends: BFormInput,
    compatConfig: {
        MODE: 3
    }
});
app.component("vue-slider", {
    extends: VueSlider,
    compatConfig: {
        MODE: 3
    }
})


app.mixin({
    data: function () {
        return {
            get CUSTOMER() {
                return import.meta.env.VUE_APP_CUSTOMER;
            }
        };
    }
});

app.config.globalProperties.$log = function (type, data, created) {
    return this.$store.getters.api
        .post(CONFIG.api.endpoints.log, {
            type: type,
            created: created || new Date(Date.now()),
            data: data
        })
}

app.config.globalProperties.$overlayTender = function (id, disabledSupplierLinks = false) {
    this.$ebus.emit('show-overlay', { component: "tender-overview", props: { id: id, disabledSupplierLinks: disabledSupplierLinks } })
}

app.config.globalProperties.$overlaySupplier = function (id) {
    this.$ebus.emit('show-overlay', { component: "supplier-overview", props: { id: id, tab: 0 } })
}

app.config.globalProperties.$ebus = ebus

app.directive('b-tooltip', {
    updated(el) {
        let title = el.getAttribute('title')
        if (title) {
            el.setAttribute("data-bs-original-title", title)
            el.removeAttribute("title")
        } else {
            el.removeAttribute("data-bs-original-title")
            el.removeAttribute("title")
        }
    },
    mounted(el, binding) {
        let trigger = 'hover focus'
        if (binding.modifiers.hover) {
            trigger = 'hover'
        } else if (binding.modifiers.focus) {
            trigger = 'focus'
        }

        let placementOptions = [
            'auto', 'auto-start', 'auto-end', 'top', 'top-start', 'top-end', 'bottom','bottom-start',
            'bottom-end' ,'right' ,'right-start' ,'right-end' ,'left' ,'left-start' ,'left-end'
        ]
        let placement = 'auto'
        for (let n in binding.modifiers) {
            if (placementOptions.indexOf(n) >= 0) {
                placement = n
                break
            }
        }

        Tooltip.getOrCreateInstance(el, {
            customClass: 'b-tooltip',
            trigger: trigger,
            popperConfig: function() {
                return {placement: placement}
            }
        })
    },
    unmounted(el) {
        Tooltip.getInstance(el)?.dispose()
    }
})

app.use(router);
app.use(store);
app.use(i18n);

app.config.globalProperties.$filters = FILTERS
app.mount("#app");
