<template>
    <div>
        <div key="row" class="row result-row align-items-center" v-bind:class="{ 'result-row-checked': selected }">
            <div class="col-1">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" class="checkbox" v-model="selected" @click="rowSelected" />
                        <span class="cr">
                            <font-awesome-icon icon="check" class="cr-icon" />
                        </span>
                    </label>
                </div>
            </div>

            <div @click="toggleShowTenders" class="col-8 clickable">
                <div class="row row-content align-items-center">
                    <div class="col-5">
                        <h4>{{ item.name }}</h4>
                        <span class="fw-light">{{ item.organization_id }}</span>
                    </div>
                    <div class="col-4">
                        <h6>{{ $t("result.references.value") }}</h6>
                        {{ $filters.humanizePrice(item.reference_tenders_sum) }}
                    </div>
                    <div class="col-3">
                        <h6>{{ $t("result.references.count") }}</h6>
                        {{ item.reference_tenders_count }}
                    </div>
                </div>
            </div>

            <div class="col-3">
                <div class="row row-content align-items-center">
                    <div class="col text-end">
                        <b-button size="sm" variant="outline-primary" class="result-row-button" @click="$overlaySupplier(item.id)">{{ $t("result.supplierDetailButton") }}</b-button>
                    </div>
                </div>
            </div>
        </div>

        <Loader v-show="showTenders && busy" class="supplier-tenders-loader" />

        <span v-show="showTenders && !busy && item.tenders?.length || false">
            <h5>{{ $t("result.referencedTenders") }}</h5>
            <ResearchResultListTenderRow
                v-for="tender in item.tenders"
                :key="'tref-' + tender.id"
                ref="tender"
                :item="tender"
                :isSubRow="true"
                :isSubRowChecked="selected"
                :selectedIds="[]"
            />
        </span>

        <span v-show="showTenders && !busy && item.relevant_tenders?.length || false">
            <h5>{{ $t("result.relevantTenders") }}</h5>
            <ResearchResultListTenderRow
                v-for="tender in item.relevant_tenders"
                :key="'trel-' + tender.id"
                ref="tender"
                :item="tender"
                :isSubRow="true"
                :isSubRowRelevantTender="true"
                :isSubRowChecked="selected"
                :selectedIds="[]"
            />
        </span>
    </div>
</template>


<script>
import ResearchResultListTenderRow from "@/components/ResearchResultListTenderRow.vue";
import Loader from "@/components/Loader.vue";
import researchResultRowMixin from "@/plugins/researchResultRowMixin.js";

export default {
    name: "research-result-list-supplier-row",
    data: function() {
        return {
            showTenders: false,
            busy: false,
            supplierTendersLoaded: false
        }
    },
    components: { ResearchResultListTenderRow, Loader },
    mixins: [researchResultRowMixin],
    mounted: function() {},
    methods: {
        toggleShowTenders: function() {
            this.showTenders = !this.showTenders

            if (this.showTenders && !this.supplierTendersLoaded) {
                this.loadSupplierTenders()
            }
        },
        loadSupplierTenders: function() {
            this.busy = true
            this.$store.getters.api
                .post(`supplier_result_tenders/${this.item.id}`, {
                    criteria: this.$store.getters.research.search_criteria
                })
                .then(r => {
                    this.item.tenders = r.data.tenders
                    this.item.relevant_tenders = r.data.relevant_tenders
                    this.supplierTendersLoaded = true
                })
                .catch(e => {
                    console.log(e)
                    this.item.tenders = null
                    this.item.relevant_tenders = null
                    this.supplierTendersLoaded = false
                })
                .finally(() => this.busy = false)
        }
    }
};
</script>

<style scoped lang="scss">
.supplier-tenders-loader {
    padding: 12px;
    padding-bottom: 24px;
}
</style>