<template>
    <ResearchResultList
        research-type="MarketResearchType.TENDERS"
        :is-save-enabled="isSaveEnabled"
        :selected="selected"
        :orderby="orderby"
        @search-criteria-changed="refreshData"
        @research-prerequisities-loaded="lockItemLoaders = false"
    >
        <ResearchResultListTable
            row-component="research-result-list-tender-row"
            :order-options="orderOptions"
            :default-order="defaultOrder"
            :items-loader="tendersLoader"

            @selected-items-update="onSelectedItemsUpdate"

            ref="tenders"
        >
            <template #found-label="{count, total}">
                <span v-if="count < total" v-html="$t('result.found.tenderOverLimit', {count: count, total: total})" />
                <span v-else v-html="$tc('result.found.tender', count)" />
            </template>
            <template #no-items>
                <Empty :title="$t('result.nodata.tenders.title')" :subtitle="$t('result.nodata.tenders.subtitle')" />
            </template>
        </ResearchResultListTable>
    </ResearchResultList>
</template>


<script>
import ResearchResultList from '@/components/ResearchResultList.vue'
import ResearchResultListTable from '@/components/ResearchResultListTable.vue'
import Empty from '@/components/overlay/Empty.vue'
import { CONFIG } from '@/config.js'

export default {
    data: function() {
        return {
            isSaveEnabled: false,
            selected: [],
            lockItemLoaders: true,
            orderby: null
        }
    },
    components: { ResearchResultList, ResearchResultListTable, Empty },
    computed: {
        research: function() {
            return this.$store.getters.research
        },
        orderOptions: function() {
            let options = [
                {
                    name: this.$t("result.order.tender.options[0]"),
                    id: 'newest',
                },
                {
                    name: this.$t("result.order.tender.options[1]"),
                    id: 'oldest',
                },
                {
                    name: this.$t("result.order.tender.options[2]"),
                    id: 'price_desc',
                },
                {
                    name: this.$t("result.order.tender.options[3]"),
                    id: 'price_asc',
                },
                {
                    name: this.$t("result.order.tender.options[4]"),
                    id: 'name',
                },
                {
                    name: this.$t("result.order.tender.options[5]"),
                    id: 'buyer',
                },
                {
                    name: this.$t("result.order.tender.options[6]"),
                    id: 'supplier',
                },
                {
                    name: this.$t("result.order.tender.options[7]"),
                    id: 'cpv_code',
                },
                {
                    name: this.$t("result.order.tender.options[8]"),
                    id: 'cpv_name',
                }
            ]

            return options
        },
        defaultOrder: function() {
            return this.orderOptions ? this.orderOptions[0].id : null
        }
    },
    watch: {
        selected: {
            handler: function() {
                this.isSaveEnabled = this.selected.length > 0
            },
            deep: true
        }
    },
    methods: {
        refreshData: function() {
            if (this.$refs["tenders"]) {
                this.$refs["tenders"].loadItems()
                this.$refs["tenders"].selectedIds = []
            }
            this.selected = []
        },
        onSelectedItemsUpdate: function(selected) {
            this.selected = selected
        },
        tendersLoader: function({orderby}) {
            this.orderby = orderby

            if (this.lockItemLoaders) {
                return null
            }

            return this.$store.getters.api
                .post(CONFIG.api.endpoints.tender.list, { criteria: this.research.search_criteria, orderby: orderby })
        }
    }
}
</script>

<style scoped lang="scss">
</style>