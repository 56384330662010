<template>
    <Loader v-if="loading && !items.length && lockOnOrderChange" />
    <div v-else class="overview-tenders-table">
        <slot v-if="!loading && !totalCount" name="no-items"></slot>
        <template v-else>
            <div class="row align-items-center controls-row">
                <div class="col-6 text-start">
                    <slot name="found" v-bind="{count: totalCount}">Found {{ totalCount }} items</slot>
                </div>

                <div class="col-6 text-end">
                    <div class="row small-gutters justify-content-end">
                        <div v-if="orderOptions" class="col-auto select-column">
                            <multiselect
                                v-model="selectedOrderOption"
                                track-by="id"
                                label="name"
                                :show-labels="false"
                                :hide-selected="true"
                                :options="orderOptions"
                                :searchable="false"
                                :allow-empty="false"
                                :preselectFirst="true"
                                @select="onOrderChange"
                                @open="lockOnOrderChange = false"
                            >
                                <template #singleLabel="{ option }">
                                    <div class="selected-option-truncate">{{ option.name }}</div>
                                </template>
                            </multiselect>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row align-items-center">
               <div class="col">
                    <TenderRow v-for="(item, i) in items" :key="`row-${i}`" :item="item" :disabled-supplier-links="disabledSupplierLinks"/>

                    <div class="row text-center mt-4">
                       <div class="col">
                            <Loader v-if="loading" />
                            <b-button v-else-if="!lastPage" @click="loadItems(currentPage + 1)" size="sm" block variant="outline-primary">
                                {{ $t('overview.next') }}
                            </b-button>
                            <slot v-else-if="lastPage && currentPage != 1" name="all-items"></slot>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>


<script>
import Loader from "@/components/Loader.vue";
import TenderRow from "@/components/overlay/TenderRow.vue";
import orderingMixin from "@/plugins/orderingMixin.js";

export default {
    props: {
        supplier: { type: Object, required: true},
        tendersLoader: { type: Function, required: true},
        disabledSupplierLinks: { type: Boolean, required: false, default: false}
    },
    data: function() {
        return {
            selectedOrderOption: null,
            loading: false,
            items: [],
            totalCount: 0,
            currentPage: 1,
            lastPage: false,
            lockOnOrderChange: true
        };
    },
    components: {
        Loader,
        TenderRow
    },
    mixins: [orderingMixin],
    computed: {
        orderOptions: function() {
            return [
                {
                    name: this.$t("result.order.tender.options[0]"),
                    id: 'newest',
                },
                {
                    name: this.$t("result.order.tender.options[2]"),
                    id: 'price_desc',
                }
            ]
        },
        defaultOrder: function() {
            return this.orderOptions ? this.orderOptions[0] : null
        }
    },
    mounted: function() {
        this.selectedOrderOption = this.defaultOrder
        this.loadItems(this.currentPage)
    },
    methods: {
        loadItems: function(page) {
            this.loading = true
            this.tendersLoader(page, this.selectedOrderOption.id)
                .then(response => {
                    this.items = this.items.concat(response.data.data)
                    this.totalCount = response.data.pagination.total
                    this.currentPage = page
                    this.lastPage = response.data.pagination.last
                })
                .catch(() => {
                    this.items = []
                    this.totalCount = 0
                    this.currentPage = 1
                    this.lastPage = false
                })
                .finally(() => {
                    this.loading = false
                    this.$emit('count', this.totalCount)
                })
        },
        onOrderChange: function() {
            if (this.lockOnOrderChange) {
                return
            }

            this.items = []
            this.loadItems(1);
        }
    }
};
</script>

<style lang="scss">
    .overview-tenders-table .result-row {
        border-left: none;
    }
</style>
