<template>
    <li class="nav-item">
        <b-link :to="to" :href="href" :target="target" class="nav-link" @click="$emit('click', $event)">
            <slot></slot>
        </b-link>
  </li>
</template>

<script>
    export default {
        name: "b-nav-item",
        props: {
            to: {type: [String, Object]},
            href: {type: String},
            target: {type: String}
        }
    }
</script>

<style lang="scss">

</style>