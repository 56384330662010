<template>
    <ResearchResultList
        research-type="MarketResearchType.SUPPLIER_TENDERS"
        :is-save-enabled="isSaveEnabled"
        :selected="selected"
        @search-criteria-changed="refreshData"
        @research-prerequisities-loaded="lockItemLoaders = false"
    >
        <h3>{{ $t("result.referencedTenders") }}</h3>
        <ResearchResultListTable
            row-component="research-result-list-tender-row"
            :order-options="orderOptions"
            :default-order="defaultOrder"
            :items-loader="wonTendersLoader"

            @results-list-order="onOrderChange"
            @selected-items-update="onSelectedWonItemsUpdate"

            ref="won-tenders"
        >
            <template #found-label="{count, total}">
                <span v-if="count < total" v-html="$t('result.found.tenderOverLimit', {count: count, total: total})" />
                <span v-else v-html="$tc('result.found.tender', count)" />
            </template>
            <template #no-items>
                <Empty :title="$t('result.nodata.wonTenders.title')" :subtitle="$t('result.nodata.wonTenders.subtitle')" />
            </template>
        </ResearchResultListTable>

        <h3 class="mt-5">{{ $t("result.relevantTenders") }}</h3>
        <ResearchResultListTable
            row-component="research-result-list-tender-row"
            :row-props="{isSubRowRelevantTender: true}"
            :order-options="orderOptions"
            :default-order="defaultOrder"
            :items-loader="lostTendersLoader"

            @selected-items-update="onSelectedLostItemsUpdate"

            ref="lost-tenders"
        >
            <template #found-label="{count, total}">
                <span v-if="count < total" v-html="$t('result.found.tenderOverLimit', {count: count, total: total})" />
                <span v-else v-html="$tc('result.found.tender', count)" />
            </template>
            <template #no-items>
                <Empty :title="$t('result.nodata.lostTenders.title')" :subtitle="$t('result.nodata.lostTenders.subtitle')" />
            </template>
        </ResearchResultListTable>
    </ResearchResultList>
</template>


<script>
import ResearchResultList from '@/components/ResearchResultList.vue'
import ResearchResultListTable from '@/components/ResearchResultListTable.vue'
import Empty from '@/components/overlay/Empty.vue'
import { CONFIG } from '@/config.js'

export default {
    data: function() {
        return {
            isSaveEnabled: false,
            wonSelected: [],
            lostSelected: [],
            lockItemLoaders: true
        }
    },
    components: { ResearchResultList, ResearchResultListTable, Empty },
    computed: {
        research: function() {
            return this.$store.getters.research
        },
        orderOptions: function() {
            return [
                {
                    name: this.$t("result.order.tender.options[0]"),
                    id: 'newest',
                },
                {
                    name: this.$t("result.order.tender.options[1]"),
                    id: 'oldest',
                },
                {
                    name: this.$t("result.order.tender.options[2]"),
                    id: 'price_desc',
                },
                {
                    name: this.$t("result.order.tender.options[3]"),
                    id: 'price_asc',
                },
                {
                    name: this.$t("result.order.tender.options[4]"),
                    id: 'name',
                }
            ]
        },
        defaultOrder: function() {
            return this.orderOptions ? this.orderOptions[0].id : null
        },
        selected: function() {
            return this.wonSelected.concat(this.lostSelected)
        }
    },
    watch: {
        wonSelected: {
            handler: function() {
                this.isSaveEnabled = this.selected.length > 0
            },
            deep: true
        },
        lostSelected: {
            handler: function() {
                this.isSaveEnabled = this.selected.length > 0
            },
            deep: true
        }
    },
    methods: {
        refreshData: function() {
            if (this.$refs["won-tenders"]) {
                this.$refs["won-tenders"].loadItems()
                this.$refs["won-tenders"].selectedIds = []
            }

            if (this.$refs["lost-tenders"]) {
                this.$refs["lost-tenders"].loadItems()
                this.$refs["lost-tenders"].selectedIds = []
            }

            this.wonSelected = []
            this.lostSelected = []
            this.isSaveEnabled = false
        },
        onSelectedWonItemsUpdate: function(selected) {
            this.wonSelected = selected
        },
        onSelectedLostItemsUpdate: function(selected) {
            this.lostSelected = selected
        },
        wonTendersLoader: function() {
            if (this.lockItemLoaders) {
                return null
            }

            return this.$store.getters.api
                .post(CONFIG.api.endpoints.tender.list, { criteria: this.research.search_criteria })
        },
        lostTendersLoader: function() {
            if (this.lockItemLoaders) {
                return null
            }

            var criteria = {...this.research.search_criteria}
            criteria.bidder = {...criteria.supplier}
            delete criteria.supplier

            return this.$store.getters.api
                .post(CONFIG.api.endpoints.tender.list, { criteria: criteria })
        }
    }
}
</script>

<style scoped lang="scss">
</style>