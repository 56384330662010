<template>
    <div>
        <div class="row justify-items-end">
           <div class="col">
                <b-form-group label="Filter" label-cols-sm="3" label-align-sm="end" label-size="sm" label-for="filterInput" class="mb-0 text-nowrap">
                    <b-input-group size="sm">
                        <b-form-input v-model="filter" :debounce="700" type="text" id="filterInput" placeholder="Type to Search"></b-form-input>
                        <b-button v-if="filter" @click="filter = null" variant="outline-dark">⨯</b-button>
                    </b-input-group>
                </b-form-group>
            </div>
        </div>

        <b-table id="users-stats-table" hover borderless striped responsive
            v-model:sort-by="sortBy"
            v-model:sort-desc="sortDesc"

            :items="tableData"
            :fields="tableFields"
            :per-page="perPage"
            :current-page="currentPage"
            :filter="filter"
            :filter-included-fields="filterOn"

            @filtered="onFiltered"
            @row-clicked="goToUserOverview"
        >
            <template v-slot:head(last_activity)>
                <div>poslední aktivita</div>
                <div class="text-muted">první aktivita</div>
            </template>
            <template v-slot:cell(last_activity)="{item}">
                <div>{{ $filters.datetime(item?.last_activity) }} [{{ dateDist(item?.last_activity) }}]</div>
                <div class="text-muted">{{ $filters.datetime(item?.first_activity) }} [{{ dateDist(item?.first_activity) }}]</div>
            </template>

            <template v-slot:cell(registred)="{value}">{{ $filters.datetime(value) }}</template>

            <template v-slot:cell(medians)="{value}">
                <ul class="small">
                    <li><b>počet dní neaktivity:</b> {{ value?.inactive_days }}</li>
                    <li><b>počet akcí:</b> {{ value?.actions_count }}</li>
                    <li><b>počet zobrazených zakázek:</b> {{ value?.tender_details_count }}</li>
                    <li><b>počet stažených dokumentů:</b> {{ value?.documents_downloaded_count }}</li>
                    <li><b>změny filtru - uložené průzkumy:</b> {{ value?.changes_count_saved }}</li>
                    <li><b>změny filtru - nedokončené průzkumy:</b> {{ value?.changes_count_unsaved }}</li>
                </ul>
            </template>

            <template v-slot:cell(saved_researches_count)="{value}">{{ value || 0 }}</template>

            <template v-slot:cell()="{value}">{{ value }}</template>
        </b-table>

        <div class="d-flex align-items-center">
            <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                aria-controls="users-stats-table"
                class="mb-0"
            ></b-pagination>

            <label for="perPageSelect" class="ms-4 mb-0 text-nowrap small">Per page</label>
            <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions" class="ms-2 w-auto"></b-form-select>
        </div>
    </div>
</template>

<script>
import userStatsMixin from '@/plugins/userStatsMixin.js'

export default {
    props: {
        data: { type: Object}
    },
    data: function() {
        return {
            perPage: 10,
            currentPage: 1,
            sortBy: "last_activity",
            sortDesc: true,
            filter: null,
            totalRows: 0
        }
    },
    mounted() {
        this.totalRows = this.tableData.length
    },
    mixins: [ userStatsMixin ],
    computed: {
        tableFields: function() {
            return [
                {
                    key: 'id',
                    sortable: true,
                    label: "id"
                },
                {
                    key: 'name',
                    sortable: false,
                    label: "jméno",
                    tdClass: "text-nowrap"
                },
                {
                    key: 'email',
                    sortable: false,
                    label: "email",
                    tdClass: "text-nowrap"
                },
                {
                    key: 'registred',
                    sortable: true,
                    label: "datum registrace",
                    tdClass: "text-nowrap"
                },
                {
                    key: 'last_activity',
                    sortable: true,
                    tdClass: "text-nowrap"
                },
                {
                    key: 'unique_days',
                    sortable: true,
                    label: 'počet aktivních dní'
                },
                {
                    key: 'medians',
                    sortable: true,
                    label: 'mediany akcí',
                    tdClass: "text-nowrap"
                },
                {
                    key: "saved_researches_count",
                    sortable: true,
                    label: "počet uložených průzkumů"
                }
            ]
        },
        tableData: function() {
            if (this.data == null) {
                return []
            }

            var result = []

            Object.values(this.data).forEach(n => result.push(this.getUserStats(n)))

            return result
        },
        pageOptions: function() {
            return [10, 20, 50]
        },
        filterOn: function() {
            return ["email"]
        }
    },
    methods: {
        goToUserOverview: function(item) {
            this.$router.push({ name: 'applog-overview-user', params: { id: item.id } })
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems?.length || 0
            this.currentPage = 1
        }
    }
}
</script>

<style lang="scss" scoped>
table {
    white-space: nowrap;

    ul {
        margin-bottom: 0;
        list-style-type: none;
    }
}

</style>