<template>
    <b-container fluid class="h-100" id="research_list_app" v-cloak>
        <Header :title="$t('research.list')" :subtitle="$t('research.history')" :options="{md: 12, lg: 11, xl: 10}" />

        <div class="row justify-content-center h-100">
            <div class="col-md-12 col-lg-11 col-lg-10 h-100">
                <div class="row justify-content-center text-center button-row">
                    <div class="col-4">
                        <b-link to="/main_page" class="btn btn-outline-primary btn-sm">{{ $t('research.create') }}</b-link>
                    </div>
                </div>

                <div class="row controls-row align-items-end">
                   <div class="col">
                        <div class="row align-items-center">
                            <div class="col-6 text-start">
                                <h5>{{$t('research.total')}} {{ $tc('research.research', researches.length) }}</h5>
                            </div>
                        </div>
                        <div class="row controls-row">
                            <div class="col-5 text-start">
                                <b-form-input type="text" v-model="researchFilter" :placeholder="$t('research.search')" size="lg" />
                            </div>
                            <div class="col-md-2 col-lg-3"></div>
                            <div class="col-md-5 col-lg-4 text-end">
                                <multiselect
                                    v-model="selectedShowOption"
                                    track-by="id"
                                    label="name"
                                    :show-labels="false"
                                    :hide-selected="true"
                                    :options="showOptions"
                                    :searchable="false"
                                    :allow-empty="false"
                                    class="research-multiselect">
                                </multiselect>
                            </div>
                        </div>
                    </div>
                </div>

                <template v-if="!loading">
                    <div class="row" v-if="researches">
                       <div class="col">
                            <template v-for="r in researches" :key="r.id">
                                <ResearchRow v-if="isResearchVisible(r)" :research="r"
                                    @research-delete="$ebus.emit('show-overlay', {component: 'research-delete-form', props: {id: r.id}, showBackButton: false})"
                                    @research-download-pdf="downloadPdf"
                                    @research-edit="editResearch(r)"
                                    @research-clone="cloneResearch(r)"
                                />
                            </template>
                        </div>
                    </div>
                    <p v-else>{{ $t('research.empty') }}</p>
                </template>
                <template v-else>
                    <Loader :title="$t('research.loader')" />
                </template>
            </div>
        </div>
    </b-container>
</template>

<script>
import ResearchRow from '@/components/ResearchRow.vue'
import Header from '@/components/Header.vue'
import downloadPdfMixin from '@/plugins/downloadPdfMixin.js'
import Loader from '@/components/Loader.vue'
import { CONFIG } from '@/config.js'

import accents from 'remove-accents';

export default {
    data: function() {
        return {
            researchFilter: null,
            selectedShowOption: null,
            loading: false
        }
    },
    mixins: [downloadPdfMixin],
    components: { ResearchRow, Header, Loader },
    mounted: function () {
        this.selectedShowOption = this.showOptions[0];

        this.loading = true
        this.$store.dispatch("loadResearches")
            .then(() => this.loading = false)
            .catch(() => this.loading = false)

        this.$ebus.on('delete-research', evt => this.deleteResearch(evt.id))

    },
    computed: {
        api: function() {
            return this.$store.getters.api
        },
        showOptions: function() {
            return [{
                "name": this.$t("research.show.all"),
                "type": "",
                "id": 0
            }, {
                "name": this.$t("research.show.references"),
                "type": "MarketResearchType.TENDERS",
                "id": 1
            }, {
                "name": this.$t("research.show.candidates"),
                "type": "MarketResearchType.SUPPLIERS",
                "id": 2
            }, {
                "name": this.$t("research.show.supplierTenders"),
                "type": "MarketResearchType.SUPPLIER_TENDERS",
                "id": 3
            }]
        },
        researches: function() {
            return this.$store.getters.researches || []
        },
        user: function() {
            return this.$store.getters.user || {}
        }
    },
    methods: {
        firstIncludesSecond: function(first, second) {
            first = accents.remove(first).toUpperCase();
            second = accents.remove(second).toUpperCase();
            return first.includes(second);
        },
        isResearchVisible: function(research) {
            return (!this.researchFilter || this.firstIncludesSecond(research.name, this.researchFilter))
                && (!this.selectedShowOption || !this.selectedShowOption.type
                        || this.selectedShowOption.type == research.research_type)
        },
        deleteResearch: function(id) {
            this.loading = true

            this.$ebus.emit('close-overlay')

            this.api
                .get(CONFIG.api.endpoints.research.delete(id))
                .then(() => {
                    this.$store.commit("removeResearchById", id)
                })
                .catch(e => console.log(e))
                .finally(() => this.loading = false)
        },
        isTendersResearch: function(research) {
            return research.research_type == 'MarketResearchType.TENDERS'
        },
        isSuppliersResearch: function(research) {
            return research.research_type == 'MarketResearchType.SUPPLIERS'
        },
        isSupplierTendersResearch: function(research) {
            return research.research_type == 'MarketResearchType.SUPPLIER_TENDERS'
        },
        editResearch: function(research) {
            this.pushResearchFormRoute(research, "edit")
        },
        cloneResearch: function(research) {
            this.pushResearchFormRoute(research, "duplicate")
        },
        pushResearchFormRoute: function(research, action) {
            var options = {}
            if (this.isTendersResearch(research)) {
                options = {
                    name: "tender-research-form",
                    params: {
                        form: "tender_" + action
                    }
                }
            } else if (this.isSuppliersResearch(research)) {
                options = {
                    name: "supplier-research-form",
                    params: {
                        form: "supplier_" + action
                    }
                }
            } else if (this.isSupplierTendersResearch(research)) {
                options = {
                    name: "supplier-tenders-research-form",
                    params: {
                        form: "supplier-tenders_" + action
                    }
                }
            }

            options.params.id = research.id
            this.$router.push(options)
        }
    }
};
</script>

<style scoped lang="scss">
@import 'src/scss/custom';

.button-row {
    margin-bottom: 70px;
}

.controls-row {
    padding-top: 10px;
    padding-bottom: 10px;
}

.controls-row .search-result-count {
    font-weight: 500;
    margin-bottom: 12px;
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #a9bbc3;
    border-color: #a9bbc3;
    opacity: 1;
}

h5 {
    font-size: 1rem;
    color: #8d9298;
    margin-left: 10px;
    margin-bottom: 0.2rem;
}
</style>