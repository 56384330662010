<template>
    <div>
        <div
            key="row"
            class="row result-row align-items-center p-4"
            v-bind:class="{
                    'result-row-checked': selected,
                    'sub-result-row-relevant': isSubRowRelevantTender,
                    'sub-result-row-checked': isSubRowChecked || (isSubRowRelevantTender && selected),
                    'sub-result-row': isSubRow
                }"
        >
            <div class="col-1">
                <div v-if="!isSubRow" class="checkbox">
                    <label>
                        <input type="checkbox" class="checkbox" v-model="selected" @click="rowSelected" />
                        <span class="cr">
                            <font-awesome-icon icon="check" class="cr-icon" />
                        </span>
                    </label>
                </div>
            </div>
            <div  @click.stop.prevent="$overlayTender(item.id)" class="col-8 clickable row-content">
                <div class="row">
                    <div class="col row-header-column">
                        <span v-if="item.award_date" class="award-date">{{ $filters.date(item.award_date) }}</span>
                        <br />
                        <h4>{{ item.name }}</h4>
                        <h5 v-if="item.lot_name && item.name != item.lot_name">{{ item.lot_name }}</h5>
                        <i class="fw-light small" v-if="item.cpv">
                            {{ item.cpv.code }} {{ item.cpv.name }}
                        </i>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <h6>{{ $t('result.buyer') }}</h6>
                        <template v-if="item.buyer">{{ item.buyer.name }}</template>
                    </div>
                    <div class="col-4 text-center price-column">
                        <img src="/img/arrow.svg" class="price-arrow" />
                        <div class="price" v-if="item.price_national">{{ $filters.number($filters.integer(item.price_national)) }} {{ $t("currency.czk") }}</div>
                    </div>
                    <div class="col-4">
                        <h6>{{ $t('result.supplier') }}</h6>
                        <a href="#" v-if="item.supplier" @click.stop.prevent="$overlaySupplier(item.supplier.id)">{{ item.supplier.name }}</a>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <h6>{{ $t('result.docs.title') }}</h6>
                <div v-for="(d, i) in documents" :key="i" :class="[d.exists ? 'doc-available' : 'doc-na']">
                    <font-awesome-icon :icon="['fas', 'circle']" class="align-baseline dot" />
                    {{ d.text }}
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import researchResultRowMixin from "@/plugins/researchResultRowMixin.js"

export default {
    name: "research-result-list-tender-row",
    props: {
        isSubRow: { type: Boolean, default: false },
        isSubRowChecked: { type: Boolean, default: false },
        isSubRowRelevantTender: { type: Boolean, default: false }
    },
    mixins: [researchResultRowMixin],
    computed: {
        documents: function() {
            return [
                {
                    text: this.$t("result.docs.spec"),
                    exists: this.item.doc_specification > 0
                },
                {
                    text: this.$t("result.docs.contract"),
                    exists: this.item.doc_contract > 0
                },
                {
                    text: this.$t("result.docs.other"),
                    exists: this.item.doc_other > 0
                }
            ]
        }
    }
}
</script>

<style scoped lang="scss">
</style>